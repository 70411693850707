<template>
  <div id="nav-bar" class="nav-bar">
    <NuxtLink class="logo-desc-container" to="/">
      <div class="game_tool-container">
        <span>BLACKMYTH</span>
      </div>
    </NuxtLink>
    <div class="middle-container">
      <div class="game-list-container">
        <div class="list">
          <div
              v-for="(item, index) in gameListFilter()"
              :key="index"
              :class="{
              'item-active': isGameActive(item.key),
              'item-6': index >= 5,
              'item-4': index >= 3
            }"
              class="item"
          >
            <nuxt-link
                :to="jumpGameUrl(item.key)"
                @click="changeGame(item.key)"
            > {{ $t(item.name) }}
            </nuxt-link>
          </div>
        </div>
        <div class="more-games" @click="loadMoreGames">
          <span>{{ $t('seeMore') }}</span>
          <!--          <span>{{ getSelectedGameName() }}</span>-->
          <NuxtImg
              :src="ImageUtil.getLocalImgFromCdn('ic_arrow_down_white')"
              alt="see more games"
              width="20"
              height="20"
          />
        </div>
      </div>
    </div>
    <!--    <div class="right-panel">-->
    <!--      <div v-show="!isLogin" @click="showLoginDialog">-->
    <!--        <el-button class="login-btn">Sign in</el-button>-->
    <!--      </div>-->
    <!--      <div v-show="isLogin" class="login-info" @click="showUserInfoDialog">-->
    <!--        <NuxtImg-->
    <!--          :placeholder="ImageUtil.getLocalImgFromCdn('ic_avatar_placeholder')"-->
    <!--          :src="getUserAvatar()"-->
    <!--          alt="user avatar"-->
    <!--          height="20"-->
    <!--          width="20"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <client-only>
    <el-dialog
        v-model="showMoreGamesPanel"
        :fullscreen="isMobile.value"
        :show-close="isMobile.value"
        class="custom-el-dialog"
        top="52px"
        width="1280px"
    >
      <GamesCategoryList @confirm="onSwitchGame"></GamesCategoryList>
    </el-dialog>
  </client-only>
  <!--  <LoginDialog-->
  <!--    :show-dialog="showLoginDialogValue"-->
  <!--    @close="showLoginDialogValue = false"-->
  <!--  />-->
  <!--  <LoginInfoDialog v-model="showLoginInfoValue"></LoginInfoDialog>-->
</template>

<script lang="ts" setup>
// import ImageUtil from '../../util/image-util.ts';
// import LoginInfoDialog from '../account/login-info-dialog.vue';
// import SetLang from '@/components/common/set-lang.vue';
import GameUtil from '../../util/game-util';
import GamesCategoryList from '../games/games-catagory-list.vue';
import { ref } from 'vue';
import type { GameDetail } from '../pb/entity/game';
import { useMediaQuery } from '@vueuse/core';
import ImageUtil from "~/util/image-util";
// import LoginDialog from '../account/login-dialog.vue';
const { $isDevEnv } = useNuxtApp();

const route = useRoute();
const isMobile = ref(false);
const showMoreGamesPanel = ref(false);
// const { $getLoginUser } = useNuxtApp();
// const showLoginDialogValue = ref(false);
// const showLoginInfoValue = ref(false);
// const isLogin = computed(() => {
//   return useLoginState().value;
// });

// const showLoginDialog = () => {
//   console.log('showLoginDialog');
//   showLoginDialogValue.value = true;
// };

// const getUserAvatar = () => {
//   let user = $getLoginUser();
//   if (user == null || user == '') {
//     return ImageUtil.getLocalImgFromCdn('ic_avatar_placeholder');
//   }
//   //user是个json字符串，是否需要解析一下
//   return user.avatar;
// };

// const getSelectedGameName = () => {
//   let gameDetail = useState('selectedGameDetail').value;
//   if (gameDetail == null) {
//     return '';
//   }
//   return gameDetail.shortName;
// };

const props = defineProps({
  gameList: {
    type: Array,
    required: false,
    default: null
  }
});

const isGameActive = (gameKey: string) => {
  let currentGameKey = route.params.game;
  if (currentGameKey == null && currentGameKey == undefined) {
    currentGameKey = GameUtil.defaultGameDetail.key;
  }
  return gameKey == currentGameKey && route.params.game != undefined;
};

const getGameDetail = ({
                         gameKey,
                         gameList
                       }: {
  gameKey: string;
  gameList: GameDetail[];
}): GameDetail | null => {
  if (gameList.length > 0) {
    const game = gameList.find((item) => item.key === gameKey);
    if (game) {
      return game;
    }
  }
  return null;
};

const gameListFilter = () => {
  if (props.gameList == null) {
    return [];
  }
  return props.gameList;
};
const changeGame = (gameKey: string) => {
  let gameDetail = getGameDetail({ gameKey, gameList: props.gameList });
  useState('selectedGameDetail').value = gameDetail;
};

//拼接url
const jumpGameUrl = (gameKey: string) => {
  if(gameKey === "black-myth-wukong"){
    return '/';
  }
  let gameDetail = getGameDetail({ gameKey, gameList: props.gameList });
  let tabs = gameDetail?.tabs?.sort((a, b) => {
    if (a.key === 'map') return 1;
    if (b.key === 'map') return -1;
    return 0;
  });
  const domain = $isDevEnv() ? "game-tool-dev-xxxx.pages.dev" : "gametool.gg";
  if (tabs) {
    return `https://${domain}/games/${gameKey}/${tabs[0].key}`;
  }
  return `https://${domain}/games/${gameKey}`;
};

// const showUserInfoDialog = () => {
//   showLoginInfoValue.value = true;
// };

const loadMoreGames = () => {
  showMoreGamesPanel.value = true;
};

const onSwitchGame = () => {
  showMoreGamesPanel.value = false;
};

onMounted(() => {
  isMobile.value = useMediaQuery('(max-width: 999px)');
});
</script>

<style scoped>
.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 52px;
  background-color: rgba(19, 31, 40, 1);
  align-items: center;
  display: flex;
  text-decoration: none;
  justify-content: left;
  z-index: 1;
  width: 100vw;
  padding-right: 40px;
  width: calc(100vw - 30px);
}

.logo-desc-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 52px;
  display: flex;
  align-items: center;
  text-decoration: none;
  min-width: 160px;
  height: 52px;
  padding-left: 10px;
}

.right-panel {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.nav-bar .game-list-container .more-games span:nth-child(2) {
  display: none;
}

.nav-bar .logo-desc-container {
  text-decoration: none;
}

.nav-bar .logo-desc-container img:first-child {
  margin-right: 16px;
}

.nav-bar .logo-image {
  height: 46px;
  aspect-ratio: 1;
}

.nav-bar .game_tool-logo-image {
  width: 111px;
  aspect-ratio: 223/33;
}

.game_tool-container span {
  display: block;
  margin-bottom: 0px;
  color: orangered;
  font-size: 20px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-lines: 1;
}

.nav-bar .middle-container {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: calc((100vw - 750px) / 2 - 8px);
  overflow-x: auto;
}

.nav-bar .middle-container .game-list-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.nav-bar .middle-container .game-list-container .home a {
  font-size: 20px;
  font-weight: 600;
  color: white;
  text-decoration: none;
}

.nav-bar .middle-container .game-list-container .home-inactive a {
  color: rgba(255, 255, 255, 0.7);
}

.nav-bar .game-list-container .list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto;
  gap: 20px;

  /* Webkit browsers like Chrome, Safari */

  &::-webkit-scrollbar {
    width: 1px; /* Set the width of the scrollbar */
    height: 1px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Set the track (background) color */
  }

  &::-webkit-scrollbar-thumb {
    background: #888; /* Set the thumb color */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Set the thumb hover color */
  }

  /* Firefox */
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}
.list .item {
  min-width: 80px;
  display: flex;
  justify-content: center;
}
.nav-bar .game-list-container .item a {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-lines: 1;
}

.list .item-6 {
  display: none;
}

.nav-bar .game-list-container .item-active a {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

.nav-bar .game-list-container .more-games {
  cursor: pointer;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-bar .game-list-container .more-games img {
  width: 20px;
}

.right-panel .login-btn {
  width: 150px;
  height: 40px;
  background-color: #284b67;
  border-color: transparent;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.right-panel .login-info img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@media screen and (max-width: 1300px) {
  .nav-bar .middle-container {
    padding-left: calc((100vw - 570px) / 2 - 8px);
  }

  .list .item-4 {
    display: none;
  }
}

@media screen and (max-width: 999px) {
  .logo-desc-container {
    display: none;
  }

  .nav-bar {
    height: 56px;
    background-color: rgba(25, 40, 52, 194);
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .nav-bar .logo-desc-container {
    margin-left: 16px;
    align-items: center;
  }

  .nav-bar .logo-desc-container img:first-child {
    margin-right: 16px;
  }

  .nav-bar .game_tool-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .nav-bar .game_tool-container span {
    display: block;
    margin-bottom: 0px;
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    margin-top: 4px;
  }

  .nav-bar .logo-image {
    height: 40px;
    width: 40px;
  }

  .nav-bar .game_tool-logo-image {
    height: 12px;
    aspect-ratio: 223/33;
  }

  .nav-bar .middle-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(0%, -50%);
  }

  .nav-bar .middle-container .game-list-container .home {
    display: none;
  }

  .nav-bar .game-list-container .more-games span:nth-child(2) {
    display: flex;
  }

  .nav-bar .game-list-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 16px;
    max-width: 150px;
    gap: 0px;
  }

  .nav-bar .game-list-container .list {
    display: none;
  }

  .nav-bar .game-list-container .more-games {
    cursor: pointer;
    font-size: 14px;
    color: white;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-bar .game-list-container .more-games span:nth-child(1) {
    display: none;
  }

  .right-panel {
    margin-right: 16px;
    display: flex;
    align-items: center;
  }

  .right-panel .login-info {
    display: none;
  }

  .login-btn {
    display: none;
  }
}

@media screen and (min-width: 1494px) {
  .logo-desc-container {
    display: flex;
    align-items: center;
  }

  .right-panel {
    display: flex;
    align-items: center;
    margin-right: calc((100vw - 1538px) / 2 - 60px);
  }

  .nav-bar .game-list-container .more-games span:nth-child(2) {
    display: none;
  }
}
</style>
