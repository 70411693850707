<template>
  <div class="app-root">
    <div v-if="!isMobileRef" class="pc-content">
      <div class="content-container" style="margin-top: 80px;">
        <TopGameTabBar :game-list="gamesList"></TopGameTabBar>
        <NuxtPage
          :keepalive="{ include: ['GameHome,GameTab'], max: 1 }"
        ></NuxtPage>
      </div>
      <GameHomeTabBar></GameHomeTabBar>
    </div>
    <div v-if="isMobileRef" class="mobile-content">
      <NuxtPage
        :keepalive="{
          include: [
            'GameHome,GameTab',
            'HomeContainer,HomePopular,TopicDetail,EventDetail'
          ],
          max: 2
        }"
      ></NuxtPage>
    </div>
  </div>
</template>

<script setup lang="ts">
import ImageUtil from '@/util/image-util.ts';
import CacheMgr, { CacheType } from '~/util/cache/cache-mgr';
import NetworkMgr from '~/util/network-mgr.ts';
import GameHomeTabBar from './components/games/game-tab-bar.vue'
import GameUtil from '@/util/game-util.ts';
// import TopGameTabBar from './components/nav-bar/top-game-bar.vue'
import TopGameTabBar from './components/nav-bar/top-nav-bar.vue'
const { $getConfigInfo, $getLoginToken, $getUserId } = useNuxtApp();
const route = useRoute();
/**
 * 1、app获取语言，然后设置给NetworkMgr，之后所有网络请求都会带上这个语言
 * 2、app.vue会被打包在entry.xxxxxx.js这个基础包里
 * 3、直接import vue-i18n，会给基础包增加60k包体,所以app这里判断语言的方式是通过cookie和url参数,和plugins/i18n.ts里的方式保持一致
 */
const options = { secure: true, path: '/', sameSite: 'Strict' };
const lang = useCookie('lang', options);
if (route.query.lang) {
  lang.value = route.query.lang;
} else if (!lang.value) {
  lang.value = 'en';
}
if (!['en', 'pt', 'zh'].includes(lang.value)) {
  lang.value = 'en';
}

//更新缓存池
CacheMgr.clear($getConfigInfo().version);
//初始化网络工具类
NetworkMgr.getInstance()
  .setApiUrl($getConfigInfo().apiUrl)
  .setLocale(lang.value)
  .setToken($getLoginToken());

const isMobileRef = computed(() => {
  return useIsMobileState().value;
});
const gamesList = ref([]);
useSeoMeta({
  title: 'Black Myth : Wukong',
  description: 'Black Myth : Wukong!',
  ogTitle: 'Black Myth : Wukong',
  ogDescription: 'Black Myth : Wukong',
  ogImage: '/img/favicon.ico',
  twitterTitle: 'Black Myth : Wukong',
  twitterDescription: 'Black Myth : Wukong',
  twitterImage: '/img/favicon.ico',
  'google-site-verification': $getConfigInfo().googleSiteVerification,
  'google-adsense-account': $getConfigInfo().googleAdsenseCount
});

useHead({
  htmlAttrs: {
    lang: lang.value
  }
});

const updateWidth = () => {
  useState('isMobileState').value = window.innerWidth < 1000;
};

const setGamePageBg = () => {
  let gameDetail = useSelectedGameDetail().value;
  let imageUrl = gameDetail.bgImage;
  imageUrl = ImageUtil.getWebpUrl(imageUrl);
  if (imageUrl != undefined && imageUrl != '' && process.client) {
    document.documentElement.style.setProperty(
      '--background-url',
      `url(${imageUrl})`
    );
  }
};

const getGameDetail = ({
                         gameKey,
                         gameList
                       }: {
  gameKey: string;
  gameList: GameDetail[];
}): GameDetail | null => {
  if (gameList.value && gameList.value.length > 0) {
    const game = gameList.value.find((item) => item.key === gameKey);
    if (game) {
      return game;
    }
  }
  return null;
};

const { data: gameListData } = await NetworkMgr.getInstance().request(
    'gameList',
    'games/games/list',
    {
      begin:"",
      count: 100,
      order: 1,
      site_id:2
    },
    // null,
    // CacheType.ONLY_SERVER   接口变化会缓存，先暂时注释
);

if (gameListData.value) {
  gamesList.value = gameListData.value.games;
  let gameKey = route.params.game;
  if (gameKey == null || gameKey == undefined) {
    gameKey = GameUtil.defaultGameDetail.key;
  }
  let gameDetail = getGameDetail({ gameKey, gameList: gamesList });
  if (gameDetail != null) {
    useState('selectedGameDetail').value = gameDetail;
  }
  if (gameDetail == null) {
    const { data: gameDetailResponse } = await NetworkMgr.getInstance().request(
        'game-detail',
        'games/games/detail',
        {
          key: route.params.game,
          site_id:2
        },
        null,
        CacheType.ONLY_SERVER
    );
    if (gameDetailResponse) {
      useState('selectedGameDetail').value = gameDetailResponse.value.game;
    }
  }
}

const { data: gameDetailResponse } = await NetworkMgr.getInstance().request(
  'game-detail',
  'games/games/detail',
  {
    game_id:10058,
    site_id: 'SITE_BLACK_MYTH',
  },
  null,
  CacheType.ONLY_SERVER
);
if (gameDetailResponse) {
  useState('selectedGameDetail').value = gameDetailResponse.value.game;
}

const setBackground = () => {
  if (isMobileRef.value) {
    return;
  }
  if (route.path.startsWith('/')) {
    setGamePageBg();
    return;
  }
};

onBeforeMount(() => {
  NetworkMgr.getInstance().setUserId($getUserId());
  setBackground();
});

onMounted(() => {
  window.addEventListener('resize', updateWidth);
});

watch(
  () => route.path,
  () => {
    setBackground();
  }
);

onUnmounted(() => {
  window.removeEventListener('resize', updateWidth);
});
</script>

<style scoped>
.app-root {
  display: flex;
}

.pc-content {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  width: 100vw;
  justify-content: center;
  overflow-x: clip;
}

.left-nav {
  width: 285px;
  position: fixed;
  left: 0;
  top: 72px;
}

.content-when-right-container {
  display: flex;
  justify-content: center;
  padding: 0;
  width: calc(100vw - 285px);
  margin-left: 285px;
}

.mobile-content {
  display: none;
}

@media screen and (max-width: 999px) {
  .mobile-content {
    display: flex;
  }

  .pc-content {
    display: none;
  }

  .app-root {
    width: 100%;
    overflow-x: hidden;
  }

  .left-nav {
    width: 0px;
    display: none;
  }

  .content-when-right-container {
    display: flex;
    justify-content: center;
    padding: 0;
    width: 100vw;
    margin-left: 0px;
  }
}

@media screen and (min-width: 1700px) {
  .left-nav {
    width: 345px;
  }

  .content-when-right-container {
    margin-left: 345px;
    width: calc(100vw - 345px);
  }
}

@media screen and (min-width: 1920px) {
  .left-nav {
    width: 385px;
  }

  .content-when-right-container {
    margin-left: 385px;
    width: calc(100vw - 385px);
  }
}

@media screen and (min-width: 2560px) {
  .left-nav {
    width: 435px;
  }

  .content-when-right-container {
    margin-left: 435px;
    width: calc(100vw - 435px);
  }
}
</style>
