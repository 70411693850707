<script lang="ts" setup>
import { ref } from 'vue';
import GameUtil from '@/util/game-util.ts';
import type { GameDetail } from '@/components/pb/entity/game';
import '@/assets/css/custom-el-dialog.css';
import NetworkMgr from '~/util/network-mgr.ts';

let isFinish = ref(false);
let loading = ref(false);
const typeGameList = ref([]);
const route = useRoute();

const { $isDevEnv } = useNuxtApp();

const jumpGameUrl = (gameKey: string, gameList: Array) => {
  if (gameKey === 'black-myth-wukong') {
    return '/';
  }
  let gameDetail = getGameDetail({ gameKey, gameList: gameList });
  let tabs = gameDetail?.tabs.sort((a, b) => {
    if (a.key === 'map') return 1;
    if (b.key === 'map') return -1;
    return 0;
  });
  const domain = $isDevEnv() ? "game-tool-dev-xxxx.pages.dev" : "gametool.gg";
  if (tabs) {
    return `https://${domain}/games/${gameKey}/${tabs[0].key}`;
  }
  return `https://${domain}/games/${gameKey}`;
};

const getGameDetail = ({
  gameKey,
  gameList
}: {
  gameKey: string;
  gameList: GameDetail[];
}): GameDetail | null => {
  if (gameList.length > 0) {
    const game = gameList.find((item) => item.key === gameKey);
    if (game) {
      return game;
    }
  }
  return null;
};

const loadGames = async () => {
  if (loading.value || isFinish.value) {
    return;
  }
  loading.value = true;
  const response = await NetworkMgr.getInstance().fetch('games/games/reco',{
    //site_id: 'SITE_BLACK_MYTH',
    site_id: 2,
  },);

  if (response) {
    typeGameList.value = response.types as TypeGames[];
  }

  loading.value = false;
};

onMounted(async () => {
  console.log('games category list on mounted');
  await loadGames();
});

const isGameActive = (gameKey: string) => {
  let currentGameKey = route.params.game;
  if (currentGameKey == null && currentGameKey == undefined) {
    currentGameKey = GameUtil.defaultGameDetail.key;
  }
  return gameKey == currentGameKey;
};
</script>
<template>
  <div class="game_category_list_root">
    <div v-for="typeGameItem in typeGameList" :key="typeGameItem.type">
      <div class="game_category_item">
        <div v-if="typeGameItem.games.length > 0" class="type-name">
          {{ typeGameItem.type.name }}
        </div>
        <div class="game_category_grid">
          <div
            v-for="game in typeGameItem.games"
            :key="game.key"
            :class="{ 'item-active': isGameActive(game.key) }"
            class="item"
          >
            <NuxtLink
              :to="jumpGameUrl(game.key,typeGameItem.games)"
            >
              <div>
                <span>{{ game.shortName }}</span>
              </div>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.game_category_list_root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f6f8f7;
}

.game_category_item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

.game_category_item .type-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-lines: 2;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: flex-start;
}

.game_category_item span:nth-child(1) {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 20px;
}

.game_category_item .game_category_grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}

.game_category_item .game_category_grid .item {
  display: flex;
  align-items: center;
  height: 46px;
  padding: 6px;
  max-width: 200px;
}

.game_category_item .game_category_grid .item-active {
}

.game_category_item .game_category_grid .item-active span:nth-child(1) {
  color: #279ff6;
}

.game_category_item .game_category_grid .item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.game_category_item .game_category_grid a {
  width: 100%;
  height: 100%;
  text-decoration: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.game_category_item .game_category_grid div {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.game_category_item .game_category_grid span:nth-child(1) {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 999px) {
  .game_category_item {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .game_category_item .game_category_grid {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 10px;
  }

  .game_category_item span:nth-child(1) {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    margin-top: 20px;
  }

  .game_category_item .game_category_grid span:nth-child(1) {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
  }
}
</style>
