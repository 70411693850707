export default defineNuxtPlugin(() => {
    const router = useRouter();
    router.onError((error, to) => {
      if (
        error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed")
      ) {
        router.push(to.fullPath);
      }
    });
});
